import React from 'react'

import linkedInIcon from '../images/social/linkedin.svg'
import twitterIcon from '../images/social/twitter.svg'
import facebookIcon from '../images/social/facebook.svg'

export const Contact = () => {
  return (
    <div id="contact" className="p-8 bg-primary-surface text-primary-beta">
      <div className="max-w-screen-sm  mx-auto space-y-2">
        <h1 className="font-display font-bold text-3xl">Get in Touch</h1>
        <p>
          Looking for technical content with simple pricing and expert delivery?
          Drop us an email at{' '}
          <a
            href="mailto:content@writeops.co.uk"
            className="font-bold text-primary-alpha hover:text-primary-alpha-highlight"
          >
            content@writeops.co.uk
          </a>
          .
        </p>

        <h2 className="font-display font-bold text-2xl">Follow Us</h2>
        <p>
          If you just want to keep up to speed with what we're doing you can
          connect with us on our social channels.
        </p>
        <ul className="flex space-x-4">
          <li>
            <a
              href="https://www.linkedin.com/company/writeops-ltd"
              className="inline-block"
            >
              <img src={linkedInIcon} className="h-8" alt="LinkedIn" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/writeops" className="inline-block">
              <img src={twitterIcon} className="h-8" alt="Twitter" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
