import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex } from 'tailwind-react-primitives'
import Img from 'gatsby-image'

import { TestimonialsQuery } from '../../graphql-types'

export const Testimonials = () => {
  const { allPrismicTestimonials } = useStaticQuery<TestimonialsQuery>(query)

  const testimonials = allPrismicTestimonials.edges
    .map(({ node }) => node.data)
    .filter(Boolean)

  return (
    <div className="bg-primary-surface text-primary-beta" id="testimonials">
      <h2 className="sr-only">What People Say</h2>
      <div className="container mx-auto p-8">
        <Flex
          className="divide-y sm:divide-y-0 sm:divide-x divide-primary-alpha-highlight"
          justify="center"
          wrap
        >
          {testimonials.map((testimonial) => (
            <blockquote
              className={[
                'w-full quote space-y-2 flex flex-col items-start',
                testimonials.length > 1 && 'sm:w-1/2 sm:max-w-md',
              ]
                .filter(Boolean)
                .join(' ')}
              key={testimonial?.company_name}
            >
              <p className="flex-grow">{testimonial?.quote?.text}</p>
              <cite className="w-full">
                <a
                  href={testimonial?.comany_website?.url || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {testimonial?.logo?.fluid && (
                    <Img
                      className="h-8 filter-grayscale hover:filter-none"
                      fluid={testimonial?.logo?.fluid}
                      aria-hidden="true"
                      imgStyle={{
                        // TODO: Need IE polyfill?
                        objectFit: 'contain',
                        objectPosition: 'left center',
                      }}
                    />
                  )}
                  <span className="sr-only">{testimonial?.company_name}</span>
                </a>
              </cite>
            </blockquote>
          ))}
        </Flex>
      </div>
    </div>
  )
}

const query = graphql`
  query Testimonials {
    allPrismicTestimonials {
      edges {
        node {
          id
          data {
            company_name
            quote {
              text
            }
            logo {
              fluid {
                srcWebp
                srcSetWebp
                srcSet
                src
                base64
                sizes
                aspectRatio
              }
            }
            comany_website {
              url
            }
          }
        }
      }
    }
  }
`
