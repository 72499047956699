import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Flex } from 'tailwind-react-primitives'
import { PrismicServicesDataType, ServicesQuery } from '../../graphql-types'

export const Services = () => {
  const { allPrismicServices } = useStaticQuery<ServicesQuery>(query)

  const { content, marketing } = React.useMemo(() => {
    const content: PrismicServicesDataType[] = []
    const marketing: PrismicServicesDataType[] = []

    allPrismicServices.edges.forEach(({ node }) => {
      const { data } = node

      if (!data) return

      switch (data.category) {
        case 'content':
          content.push(data)
          break
        case 'marketing':
          marketing.push(data)
          break
        default:
          console.error('Unexpected content type', data)
      }
    })

    return { content, marketing }
  }, [allPrismicServices])

  return (
    <div id="services" className="p-8">
      <div className="max-w-screen-sm mx-auto space-y-2">
        <h2 className="font-display font-bold text-3xl text-center">
          What We Do
        </h2>

        <h3 className="font-display text-2xl font-bold">
          Technical Content for Engineers
        </h3>

        <p>
          Great technical content requires expertise. WriteOps will connect your
          company with that expertise.
        </p>

        <p>
          Over 100 engineers write technical content with WriteOps, in
          conjunction with professional copywriters.
        </p>

        <p>
          We write SEO Optimized, compelling technical content at all levels of
          detail. From coding tutorials to marketing copy.
        </p>
      </div>

      <ServiceList services={content} />

      <div className="max-w-screen-sm mx-auto space-y-2">
        <h3 className="font-display text-2xl font-bold">
          Technical Marketing Consultancy
        </h3>
        <p>
          Your image in the technical community is crucial. We have years of
          marketing, engineering and copywriting experience to help you project
          the best possible version of your organization into the world.
        </p>
      </div>

      <ServiceList services={marketing} />
    </div>
  )
}

const ServiceList = ({ services }: { services: PrismicServicesDataType[] }) => {
  const span = services.length % 2 === 0 ? 'md:w-1/2' : 'md:w-1/3'

  return (
    <div className="md:p-2 max-w-screen-lg mx-auto">
      <Flex className="-my-2 md:-mx-2" wrap p={{ y: 8 }} justify="center">
        {services.map((item) => (
          <div className={`w-full ${span} py-2 px-2 `} key={item.title?.text}>
            <div className="flex flex-col h-full p-4 rounded border space-y-2 shadow border-secondary-surface">
              <h3 className="font-display text-xl font-bold">
                {item.title?.text}
              </h3>
              <p className="flex-grow">{item.description?.text}</p>
              {item.meta_details && <p>{item.meta_details.text}</p>}
              {/* CHRISCOONEY: Experimenting with hiding prices to improve CRO - seeing a lot of people drop off. */}
              {/* <p>
                <span className="font-bold text-2xl">&pound;{item.price}</span>
                {item.price_unit && <small>/{item.price_unit}</small>}
              </p> */}
            </div>
          </div>
        ))}
      </Flex>
    </div>
  )
}

const query = graphql`
  query Services {
    allPrismicServices {
      edges {
        node {
          id
          data {
            description {
              text
            }
            category
            price
            price_unit
            title {
              text
            }
            meta_details {
              text
            }
          }
        }
      }
    }
  }
`
