import React from 'react'
import { PageProps } from 'gatsby'

import { Hero } from '../components/Hero'
import { Services } from '../components/Services'
import { Testimonials } from '../components/Testimonials'
import { Contact } from '../components/Contact'
import { Footer } from '../components/Footer'
import { Articles } from '../components/Articles'
import { Layout } from '../components/Layout'

export default function Home(props: PageProps) {
  return (
    <Layout>
      <Hero />
      <main id="main">
        <Services />
        <Articles />
        <Contact />
        <Testimonials />
        <Footer />
      </main>
    </Layout>
  )
}
