import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex } from 'tailwind-react-primitives'

import { ArticlesQuery, PrismicArticleData } from '../../graphql-types'

const ArticleCard = ({
  article,
  span,
  fullSummary = false,
}: {
  article: PrismicArticleData
  span?: string
  fullSummary?: boolean
}) => {
  const url = article.url?.url && new URL(article.url?.url)

  const structuredData = {
    '@context': 'http://schema.org',
    '@type': 'Article',
    'headline': article?.title,
    'datePublished': article?.published,
    'author': {
      '@type': 'Person',
      'name': article?.author,
    },
    // 'publisher': {
    //   '@type': 'Organization',
    //   'name': 'Google',
    //   'logo': {
    //     '@type': 'ImageObject',
    //     'url': 'https://google.com/logo.jpg',
    //   },
    // },
  }

  return (
    <div className={['w-full py-2 px-2', span].filter(Boolean).join(' ')}>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <div className="flex flex-col h-full p-4 rounded border space-y-2 shadow border-secondary-surface">
        <h3 className="font-display text-xl font-bold">{article?.title}</h3>
        <div
          className={[!fullSummary && 'snippet', 'flex-grow']
            .filter(Boolean)
            .join(' ')}
        >
          {fullSummary
            ? article?.summary?.map((p) => <p key={p?.text}>{p?.text}</p>)
            : article?.summary?.length && <p>{article?.summary[0]?.text}</p>}
          {!fullSummary && (
            <span className="snippet_overlay text-neutral-surface" />
          )}
        </div>
        <div className="flex justify-between items-center">
          <p className="text-sm text-neutral-gamma">
            {article?.published}
            <br />
            {article?.author}
          </p>
          {url && (
            <a
              href={url.href}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-neutral-gamma p-2 rounded text-neutral-surface hover:bg-neutral-gamma-highlight self-end"
            >
              Read more
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

const ArticleList = ({ articles }: { articles: PrismicArticleData[] }) => {
  const span = articles.length % 2 === 0 ? 'md:w-1/2' : 'md:w-1/3'

  return (
    <div className="md:p-2 max-w-screen-lg mx-auto">
      <Flex className="-my-2 md:-mx-2" wrap p={{ y: 8 }} justify="center">
        {articles.map((article) => (
          <ArticleCard key={article?.url?.url} article={article} span={span} />
        ))}
      </Flex>
    </div>
  )
}

export const Articles = () => {
  const { allPrismicArticle } = useStaticQuery<ArticlesQuery>(query)

  const articles = allPrismicArticle.edges
    .map(({ node }) => node.data)
    .filter(Boolean)

  return (
    <div className="p-8" id="articles">
      <div className="max-w-screen-sm mx-auto space-y-2">
        <h2 className="font-display text-3xl font-bold text-center">
          Content Examples
        </h2>
        <ArticleCard fullSummary article={articles[0] as PrismicArticleData} />
      </div>
      <ArticleList articles={articles.slice(1) as PrismicArticleData[]} />
    </div>
  )
}

const query = graphql`
  query Articles {
    allPrismicArticle(limit: 4, sort: { fields: dataRaw___published, order: DESC }) {
      edges {
        node {
          id
          data {
            published
            author
            title
            url {
              url
            }
            summary {
              text
            }
          }
        }
      }
    }
  }
`
